export var COLLECTORS = 'metrics_collectors';
export var DESTROYED = 'is_destroyed';
export var EVENTS = 'events_publisher';
export var HAS_TIMEDOUT = 'has_timedout';
export var INTEGRATIONS_MANAGER = 'integrations_manager';
export var MY_SEGMENTS_CHANGE_WORKER = 'my_segments_change_worker';
export var PRODUCER = 'producer';
export var READINESS = 'readiness_gate';
export var READY = 'is_ready';
export var READY_FROM_CACHE = 'is_ready_from_cache';
export var SETTINGS = 'settings';
export var STATUS_MANAGER = 'status_manager';
export var STORAGE = 'storage';
export var IMPRESSIONS_COUNTER = 'impressions_counter';
export default {
  COLLECTORS: COLLECTORS,
  DESTROYED: DESTROYED,
  EVENTS: EVENTS,
  HAS_TIMEDOUT: HAS_TIMEDOUT,
  INTEGRATIONS_MANAGER: INTEGRATIONS_MANAGER,
  MY_SEGMENTS_CHANGE_WORKER: MY_SEGMENTS_CHANGE_WORKER,
  PRODUCER: PRODUCER,
  READINESS: READINESS,
  READY: READY,
  READY_FROM_CACHE: READY_FROM_CACHE,
  SETTINGS: SETTINGS,
  STATUS_MANAGER: STATUS_MANAGER,
  STORAGE: STORAGE,
  IMPRESSIONS_COUNTER: IMPRESSIONS_COUNTER
};