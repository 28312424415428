import objectAssign from 'object-assign';
import logFactory from '../utils/logger';
import thenable from '../utils/promise/thenable';
var log = logFactory('splitio-client:event-tracker');

function EventTracker(context) {
  var collector = context.get(context.constants.STORAGE).events;
  var integrationsManager = context.get(context.constants.INTEGRATIONS_MANAGER, true);

  function queueEventsCallback(eventData, tracked) {
    var eventTypeId = eventData.eventTypeId,
        trafficTypeName = eventData.trafficTypeName,
        key = eventData.key,
        value = eventData.value,
        timestamp = eventData.timestamp,
        properties = eventData.properties; // Logging every prop would be too much.

    var msg = "event of type \"" + eventTypeId + "\" for traffic type \"" + trafficTypeName + "\". Key: " + key + ". Value: " + value + ". Timestamp: " + timestamp + ". " + (properties ? 'With properties.' : 'With no properties.');

    if (tracked) {
      log.info("Successfully queued " + msg);

      if (integrationsManager) {
        // Wrap in a timeout because we don't want it to be blocking.
        setTimeout(function () {
          // copy of event, to avoid unexpected behaviour if modified by integrations
          var eventDataCopy = objectAssign({}, eventData);
          if (eventData.properties) eventDataCopy.properties = objectAssign({}, eventData.properties); // integrationsManager does not throw errors (they are internally handled by each integration module)

          integrationsManager.handleEvent(eventDataCopy);
        }, 0);
      }
    } else {
      log.warn("Failed to queue " + msg);
    }

    return tracked;
  }

  return {
    track: function track(eventData, size) {
      var tracked = collector.track(eventData, size);

      if (thenable(tracked)) {
        return tracked.then(queueEventsCallback.bind(null, eventData));
      } else {
        return queueEventsCallback(eventData, tracked);
      }
    }
  };
}

export default EventTracker;