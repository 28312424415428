import { _Set } from '../../utils/lang/Sets';
/**
 * Collect segments from a raw split definition.
 */

var parseSegments = function parseSegments(conditions) {
  var segments = new _Set();
  conditions.forEach(function (condition) {
    var matchers = condition.matcherGroup.matchers;
    matchers.forEach(function (matcher) {
      var matcherType = matcher.matcherType,
          userDefinedSegmentMatcherData = matcher.userDefinedSegmentMatcherData;

      if (matcherType === 'IN_SEGMENT') {
        segments.add(userDefinedSegmentMatcherData.segmentName);
      }
    });
  });
  return segments;
};

export default parseSegments;