var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import SplitContext from './SplitContext';
import { ERROR_SC_NO_FACTORY } from './constants';
import { getStatus, getSplitSharedClient } from './utils';
/**
 * Common component used to handle the status and events of a Split client passed as prop.
 * Reused by both SplitFactory (main client) and SplitClient (shared client) components.
 */
var SplitComponent = /** @class */ (function (_super) {
    __extends(SplitComponent, _super);
    function SplitComponent(props) {
        var _this = _super.call(this, props) || this;
        _this.setReady = function () {
            if (_this.props.updateOnSdkReady)
                _this.setState({ lastUpdate: Date.now() });
        };
        _this.setReadyFromCache = function () {
            if (_this.props.updateOnSdkReadyFromCache)
                _this.setState({ lastUpdate: Date.now() });
        };
        _this.setTimedout = function () {
            if (_this.props.updateOnSdkTimedout)
                _this.setState({ lastUpdate: Date.now() });
        };
        _this.setUpdate = function () {
            if (_this.props.updateOnSdkUpdate)
                _this.setState({ lastUpdate: Date.now() });
        };
        var factory = props.factory, client = props.client;
        // Log error if factory is not available
        if (!factory) {
            console.error(ERROR_SC_NO_FACTORY);
        }
        _this.state = __assign(__assign({ factory: factory,
            client: client }, getStatus(client)), { lastUpdate: 0 });
        return _this;
    }
    // Using `getDerivedStateFromProps` since the state depends on the status of the client in props, which might change over time.
    // It could be avoided by removing the client and its status from the component state.
    // But it implies to have another instance property to use instead of the state, because we need a unique reference value for SplitContext.Producer
    SplitComponent.getDerivedStateFromProps = function (props, state) {
        var client = props.client, factory = props.factory;
        var status = getStatus(client);
        // no need to compare status.isTimedout, since it derives from isReady and hasTimedout
        if (client !== state.client ||
            status.isReady !== state.isReady ||
            status.isReadyFromCache !== state.isReadyFromCache ||
            status.hasTimedout !== state.hasTimedout ||
            status.isDestroyed !== state.isDestroyed) {
            return __assign({ client: client,
                factory: factory }, status);
        }
        return null;
    };
    // Attach listeners for SDK events, to update state if client status change.
    // The listeners take into account the value of `updateOnSdk***` props.
    SplitComponent.prototype.subscribeToEvents = function (client) {
        if (client) {
            var status_1 = getStatus(client);
            if (!status_1.isReady)
                client.once(client.Event.SDK_READY, this.setReady);
            if (!status_1.isReadyFromCache)
                client.once(client.Event.SDK_READY_FROM_CACHE, this.setReadyFromCache);
            if (!status_1.hasTimedout && !status_1.isReady)
                client.once(client.Event.SDK_READY_TIMED_OUT, this.setTimedout);
            client.on(client.Event.SDK_UPDATE, this.setUpdate);
        }
    };
    SplitComponent.prototype.unsubscribeFromEvents = function (client) {
        if (client) {
            client.removeListener(client.Event.SDK_READY, this.setReady);
            client.removeListener(client.Event.SDK_READY_FROM_CACHE, this.setReadyFromCache);
            client.removeListener(client.Event.SDK_READY_TIMED_OUT, this.setTimedout);
            client.removeListener(client.Event.SDK_UPDATE, this.setUpdate);
        }
    };
    SplitComponent.prototype.componentDidMount = function () {
        this.subscribeToEvents(this.props.client);
    };
    SplitComponent.prototype.componentDidUpdate = function (prevProps) {
        if (this.props.client !== prevProps.client) {
            this.unsubscribeFromEvents(prevProps.client);
            this.subscribeToEvents(this.props.client);
        }
    };
    SplitComponent.prototype.componentWillUnmount = function () {
        // unsubscrite to SDK client events, to remove references to SplitClient instance methods
        this.unsubscribeFromEvents(this.props.client);
    };
    SplitComponent.prototype.render = function () {
        var children = this.props.children;
        return (React.createElement(SplitContext.Provider, { value: this.state }, typeof children === 'function' ?
            children(__assign({}, this.state)) :
            children));
    };
    SplitComponent.defaultProps = {
        updateOnSdkUpdate: false,
        updateOnSdkTimedout: false,
        updateOnSdkReady: true,
        updateOnSdkReadyFromCache: true,
        children: null,
        factory: null,
        client: null,
    };
    return SplitComponent;
}(React.Component));
export { SplitComponent };
/**
 * SplitClient will initialize a new SDK client and listen for its events in order to update the Split Context.
 * Children components will have access to the new client when accessing Split Context.
 *
 * Unlike SplitFactory, the underlying SDK client can be changed during the component lifecycle
 * if the component is updated with a different splitKey or trafficType prop. Since the client can change,
 * its release is not handled by SplitClient but by its container SplitFactory component.
 *
 * @see {@link https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK#advanced-instantiate-multiple-sdk-clients}
 */
function SplitClient(props) {
    return (React.createElement(SplitContext.Consumer, null, function (splitContext) {
        var factory = splitContext.factory;
        // getSplitSharedClient is idempotent like factory.client: it returns the same client given the same factory, Split Key and TT
        var client = factory ? getSplitSharedClient(factory, props.splitKey, props.trafficType) : null;
        return (React.createElement(SplitComponent, __assign({}, props, { factory: factory, client: client })));
    }));
}
export default SplitClient;
