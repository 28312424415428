import logFactory from '../utils/logger';
var log = logFactory('splitio-client');
import { evaluateFeature, evaluateFeatures } from '../engine/evaluator';
import ImpressionsTracker from '../trackers/impressions';
import EventTracker from '../trackers/event';
import tracker from '../utils/timeTracker';
import thenable from '../utils/promise/thenable';
import { matching, bucketing } from '../utils/key/factory';
/* asynchronous validations that live on the client. */

import { validateSplitExistance, validateTrafficTypeExistance } from '../utils/inputValidation';
import { SDK_NOT_READY } from '../utils/labels';
import { CONTROL } from '../utils/constants';

function ClientFactory(context) {
  var storage = context.get(context.constants.STORAGE);
  var metricCollectors = context.get(context.constants.COLLECTORS);
  var impressionsTracker = ImpressionsTracker(context);
  var eventTracker = EventTracker(context);

  function getTreatment(key, splitName, attributes, withConfig) {
    if (withConfig === void 0) {
      withConfig = false;
    }

    var taskToBeTracked = tracker.TaskNames[withConfig ? 'SDK_GET_TREATMENT_WITH_CONFIG' : 'SDK_GET_TREATMENT'];
    var stopLatencyTracker = tracker.start(taskToBeTracked, metricCollectors);
    var evaluation = evaluateFeature(key, splitName, attributes, storage);

    var wrapUp = function wrapUp(evaluationResult) {
      var treatment = processEvaluation(evaluationResult, splitName, key, attributes, withConfig, "getTreatment" + (withConfig ? 'withConfig' : ''));
      impressionsTracker.track();
      stopLatencyTracker();
      return treatment;
    };

    return thenable(evaluation) ? evaluation.then(function (res) {
      return wrapUp(res);
    }) : wrapUp(evaluation);
  }

  function getTreatmentWithConfig(key, splitName, attributes) {
    return getTreatment(key, splitName, attributes, true);
  }

  function getTreatments(key, splitNames, attributes, withConfig) {
    if (withConfig === void 0) {
      withConfig = false;
    }

    var taskToBeTracked = tracker.TaskNames[withConfig ? 'SDK_GET_TREATMENTS_WITH_CONFIG' : 'SDK_GET_TREATMENTS'];
    var stopLatencyTracker = tracker.start(taskToBeTracked, metricCollectors);

    var wrapUp = function wrapUp(evaluationResults) {
      var results = {};
      Object.keys(evaluationResults).forEach(function (splitName) {
        results[splitName] = processEvaluation(evaluationResults[splitName], splitName, key, attributes, withConfig, "getTreatments" + (withConfig ? 'withConfig' : ''));
      });
      impressionsTracker.track();
      stopLatencyTracker();
      return results;
    };

    var evaluations = evaluateFeatures(key, splitNames, attributes, storage);
    return thenable(evaluations) ? evaluations.then(function (res) {
      return wrapUp(res);
    }) : wrapUp(evaluations);
  }

  function getTreatmentsWithConfig(key, splitNames, attributes) {
    return getTreatments(key, splitNames, attributes, true);
  } // Internal function


  function processEvaluation(evaluation, splitName, key, attributes, withConfig, invokingMethodName) {
    var isSdkReady = context.get(context.constants.READY, true) || context.get(context.constants.READY_FROM_CACHE, true);
    var matchingKey = matching(key);
    var bucketingKey = bucketing(key); // If the SDK was not ready, treatment may be incorrect due to having Splits but not segments data.

    if (!isSdkReady) {
      evaluation = {
        treatment: CONTROL,
        label: SDK_NOT_READY
      };
    }

    var _evaluation = evaluation,
        treatment = _evaluation.treatment,
        label = _evaluation.label,
        changeNumber = _evaluation.changeNumber,
        _evaluation$config = _evaluation.config,
        config = _evaluation$config === void 0 ? null : _evaluation$config;
    log.info("Split: " + splitName + ". Key: " + matchingKey + ". Evaluation: " + treatment + ". Label: " + label);

    if (validateSplitExistance(context, splitName, label, invokingMethodName)) {
      log.info('Queueing corresponding impression.');
      impressionsTracker.queue({
        feature: splitName,
        keyName: matchingKey,
        treatment: treatment,
        time: Date.now(),
        bucketingKey: bucketingKey,
        label: label,
        changeNumber: changeNumber
      }, attributes);
    }

    if (withConfig) {
      return {
        treatment: treatment,
        config: config
      };
    }

    return treatment;
  }

  function track(key, trafficTypeName, eventTypeId, value, properties, size) {
    if (value === void 0) {
      value = null;
    }

    if (properties === void 0) {
      properties = null;
    }

    if (size === void 0) {
      size = 1024;
    }

    var matchingKey = matching(key);
    var timestamp = Date.now();
    var eventData = {
      eventTypeId: eventTypeId,
      trafficTypeName: trafficTypeName,
      value: value,
      timestamp: timestamp,
      key: matchingKey,
      properties: properties
    }; // This may be async but we only warn, we don't actually care if it is valid or not in terms of queueing the event.

    validateTrafficTypeExistance(trafficTypeName, context, 'track');
    return eventTracker.track(eventData, size);
  }

  return {
    getTreatment: getTreatment,
    getTreatmentWithConfig: getTreatmentWithConfig,
    getTreatments: getTreatments,
    getTreatmentsWithConfig: getTreatmentsWithConfig,
    track: track
  };
}

export default ClientFactory;