import React from 'react';
import { SplitFactory as SplitSdk } from './splitio';
// exported for testing purposes
export var __factories = new Map();
// idempotent operation
export function getSplitFactory(config) {
    if (!__factories.has(config)) {
        // SplitSDK is not an idempotent operation
        var newFactory = SplitSdk(config);
        newFactory.sharedClientInstances = new Set();
        newFactory.config = config;
        __factories.set(config, newFactory);
    }
    return __factories.get(config);
}
// idempotent operation
export function getSplitSharedClient(factory, key, trafficType) {
    // factory.client is an idempotent operation
    var client = factory.client(key, trafficType);
    if (factory.sharedClientInstances) {
        factory.sharedClientInstances.add(client);
    }
    return client;
}
export function destroySplitFactory(factory) {
    // call destroy of shared clients and main one
    var destroyPromises = [];
    factory.sharedClientInstances.forEach(function (client) { return destroyPromises.push(client.destroy()); });
    destroyPromises.push(factory.client().destroy());
    // remove references to release allocated memory
    factory.sharedClientInstances.clear();
    __factories.delete(factory.config);
    return Promise.all(destroyPromises);
}
export function getIsReady(client) {
    return client.__context.get(client.__context.constants.READY, true) ? true : false;
}
export function getIsReadyFromCache(client) {
    return client.__context.get(client.__context.constants.READY_FROM_CACHE, true) ? true : false;
}
export function getHasTimedout(client) {
    return client.__context.get(client.__context.constants.HAS_TIMEDOUT, true) ? true : false;
}
export function getIsDestroyed(client) {
    return client.__context.get(client.__context.constants.DESTROYED, true) ? true : false;
}
export function getStatus(client) {
    var isReady = client ? getIsReady(client) : false;
    var hasTimedout = client ? getHasTimedout(client) : false;
    return {
        isReady: isReady,
        isReadyFromCache: client ? getIsReadyFromCache(client) : false,
        isTimedout: hasTimedout && !isReady,
        hasTimedout: hasTimedout,
        isDestroyed: client ? getIsDestroyed(client) : false,
    };
}
// Other utils
/**
 * Checks if React.useContext is available, and logs given message if not
 *
 * @param message
 * @returns boolean indicating if React.useContext is available
 */
export function checkHooks(message) {
    if (!React.useContext) {
        console.log(message);
        return false;
    }
    else {
        return true;
    }
}
// Input validation utils that will be replaced eventually
export function validateSplits(maybeSplits, listName) {
    if (listName === void 0) { listName = 'split names'; }
    if (Array.isArray(maybeSplits) && maybeSplits.length > 0) {
        var validatedArray_1 = [];
        // Remove invalid values
        maybeSplits.forEach(function (maybeSplit) {
            var splitName = validateSplit(maybeSplit);
            if (splitName)
                validatedArray_1.push(splitName);
        });
        // Strip off duplicated values if we have valid split names then return
        if (validatedArray_1.length)
            return uniq(validatedArray_1);
    }
    console.log("[ERROR] " + listName + " must be a non-empty array.");
    return false;
}
var TRIMMABLE_SPACES_REGEX = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/;
function validateSplit(maybeSplit, item) {
    if (item === void 0) { item = 'split name'; }
    // tslint:disable-next-line: triple-equals
    if (maybeSplit == undefined) {
        console.log("[ERROR] you passed a null or undefined " + item + ", " + item + " must be a non-empty string.");
    }
    else if (!isString(maybeSplit)) {
        console.log("[ERROR] you passed an invalid " + item + ", " + item + " must be a non-empty string.");
    }
    else {
        if (TRIMMABLE_SPACES_REGEX.test(maybeSplit)) {
            console.log("[WARN] " + item + " \"" + maybeSplit + "\" has extra whitespace, trimming.");
            maybeSplit = maybeSplit.trim();
        }
        if (maybeSplit.length > 0) {
            return maybeSplit;
        }
        else {
            console.log("[ERROR] you passed an empty " + item + ", " + item + " must be a non-empty string.");
        }
    }
    return false;
}
/**
 * Removes duplicate items on an array of strings.
 */
function uniq(arr) {
    var seen = {};
    return arr.filter(function (item) {
        return Object.prototype.hasOwnProperty.call(seen, item) ? false : seen[item] = true;
    });
}
/**
 * Checks if a given value is a string.
 */
function isString(val) {
    return typeof val === 'string' || val instanceof String;
}
