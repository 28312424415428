import objectAssign from 'object-assign';
import thenable from '../utils/promise/thenable';
import { find } from '../utils/lang';
import { validateSplit, validateSplitExistance, validateIfDestroyed, validateIfReady } from '../utils/inputValidation';

var collectTreatments = function collectTreatments(splitObject) {
  var conditions = splitObject.conditions; // Rollout conditions are supposed to have the entire partitions list, so we find the first one.

  var allTreatmentsCondition = find(conditions, function (cond) {
    return cond.conditionType === 'ROLLOUT';
  }); // Localstorage mode could fall into a no rollout conditions state. Take the first condition in that case.

  if (!allTreatmentsCondition) allTreatmentsCondition = conditions[0]; // Then extract the treatments from the partitions

  return allTreatmentsCondition ? allTreatmentsCondition.partitions.map(function (v) {
    return v.treatment;
  }) : [];
};

var ObjectToView = function ObjectToView(json) {
  var splitObject;

  try {
    splitObject = JSON.parse(json);
  } catch (e) {
    return null;
  }

  if (splitObject == null) return null;
  return {
    name: splitObject.name,
    trafficType: splitObject.trafficTypeName || null,
    killed: splitObject.killed,
    changeNumber: splitObject.changeNumber || 0,
    treatments: collectTreatments(splitObject),
    configs: splitObject.configurations || {}
  };
};

var ObjectsToViews = function ObjectsToViews(jsons) {
  var views = [];
  jsons.forEach(function (split) {
    var view = ObjectToView(split);
    if (view != null) views.push(view);
  });
  return views;
};

function SplitManagerFactory(_splits, context) {
  var SPLIT_FN_LABEL = 'split';
  var statusManager = context.get(context.constants.STATUS_MANAGER);
  return objectAssign( // Proto-linkage of the readiness Event Emitter
  Object.create(statusManager), {
    /**
     * Get the Split object corresponding to the given split name if valid
     */
    split: function split(maybeSplitName) {
      var splitName = validateSplit(maybeSplitName, SPLIT_FN_LABEL);

      if (!validateIfDestroyed(context) || !validateIfReady(context, SPLIT_FN_LABEL) || !splitName) {
        return null;
      }

      var split = _splits.getSplit(splitName);

      if (thenable(split)) {
        return split.then(function (result) {
          validateSplitExistance(context, splitName, result, SPLIT_FN_LABEL);
          return ObjectToView(result);
        });
      }

      validateSplitExistance(context, splitName, split, SPLIT_FN_LABEL);
      return ObjectToView(split);
    },

    /**
     * Get the Split objects present on the factory storage
     */
    splits: function splits() {
      if (!validateIfDestroyed(context) || !validateIfReady(context, 'splits')) {
        return [];
      }

      var currentSplits = _splits.getAll();

      if (thenable(currentSplits)) return currentSplits.then(ObjectsToViews);
      return ObjectsToViews(currentSplits);
    },

    /**
     * Get the Split names present on the factory storage
     */
    names: function names() {
      if (!validateIfDestroyed(context) || !validateIfReady(context, 'names')) {
        return [];
      }

      return _splits.getKeys();
    }
  });
}

export default SplitManagerFactory;