'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = setupReact;

var _reactDom = require('react-dom');

var getInstanceFromNode = void 0;
var secret = _reactDom.__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;
if (secret && secret.Events && secret.Events[0]) {
  getInstanceFromNode = secret.Events[0];
} else {
  console.warn('logrocket-react does not work with this version of React');
}

function setupReact() {
  var listener = function listener(event) {
    try {
      var fiberNode = getInstanceFromNode(event.target);
      var names = [];
      var currentElement = fiberNode;
      while (currentElement) {
        var name = typeof currentElement.elementType === 'function' && currentElement.elementType.displayName;
        if (name) {
          names.push(name);
        }
        currentElement = currentElement.return;
      }
      event.__lrName = names;
    } catch (err) {
      console.error('logrocket-react caught an error while hooking into React. Please make sure you are using the correct version of logrocket-react for your version of react-dom.');
    }
  };

  document.body.addEventListener('click', listener, { capture: true, passive: true });
}
module.exports = exports['default'];