import { uniq } from '../lang';
import logFactory from '../logger';
import { validateSplit } from './split';
var log = logFactory('');
export function validateSplits(maybeSplits, method, listName, item) {
  if (listName === void 0) {
    listName = 'split_names';
  }

  if (item === void 0) {
    item = 'split name';
  }

  if (Array.isArray(maybeSplits) && maybeSplits.length > 0) {
    var validatedArray = []; // Remove invalid values

    maybeSplits.forEach(function (maybeSplit) {
      var splitName = validateSplit(maybeSplit, method, item);
      if (splitName) validatedArray.push(splitName);
    }); // Strip off duplicated values if we have valid split names then return

    if (validatedArray.length) return uniq(validatedArray);
  }

  log.error(method + ": " + listName + " must be a non-empty array.");
  return false;
}