/**
Copyright 2016 Split Software

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
**/
import Engine from '../';
import thenable from '../../utils/promise/thenable';
import * as LabelsConstants from '../../utils/labels';
import { CONTROL } from '../../utils/constants';
var treatmentException = {
  treatment: CONTROL,
  label: LabelsConstants.EXCEPTION,
  config: null
};
export function evaluateFeature(key, splitName, attributes, storage) {
  var stringifiedSplit;

  try {
    stringifiedSplit = storage.splits.getSplit(splitName);
  } catch (e) {
    // the only scenario where getSplit can throw an error is when the storage
    // is redis and there is a connection issue and we can't retrieve the split
    // to be evaluated
    return Promise.resolve(treatmentException);
  }

  if (thenable(stringifiedSplit)) {
    return stringifiedSplit.then(function (result) {
      return getEvaluation(result, key, attributes, storage);
    });
  }

  return getEvaluation(stringifiedSplit, key, attributes, storage);
}
export function evaluateFeatures(key, splitNames, attributes, storage) {
  var stringifiedSplits;
  var evaluations = {};

  try {
    stringifiedSplits = storage.splits.fetchMany(splitNames);
  } catch (e) {
    // the only scenario where fetchMany can throw an error is when the storage
    // is redis and there is a connection issue and we can't retrieve the split
    // to be evaluated
    splitNames.forEach(function (splitName) {
      evaluations[splitName] = treatmentException;
    });
    return Promise.resolve(evaluations);
  }

  return thenable(stringifiedSplits) ? stringifiedSplits.then(function (splits) {
    return getEvaluations(splitNames, splits, key, attributes, storage);
  }) : getEvaluations(splitNames, stringifiedSplits, key, attributes, storage);
}

function getEvaluation(stringifiedSplit, key, attributes, storage) {
  var evaluation = {
    treatment: CONTROL,
    label: LabelsConstants.SPLIT_NOT_FOUND,
    config: null
  };

  if (stringifiedSplit) {
    var splitJSON = JSON.parse(stringifiedSplit);
    var split = Engine.parse(splitJSON, storage);
    evaluation = split.getTreatment(key, attributes, evaluateFeature); // If the storage is async and the evaluated split uses segment, evaluation is thenable

    if (thenable(evaluation)) {
      return evaluation.then(function (result) {
        result.changeNumber = split.getChangeNumber();
        result.config = splitJSON.configurations && splitJSON.configurations[result.treatment] || null;
        return result;
      });
    } else {
      evaluation.changeNumber = split.getChangeNumber(); // Always sync and optional

      evaluation.config = splitJSON.configurations && splitJSON.configurations[evaluation.treatment] || null;
    }
  }

  return evaluation;
}

function getEvaluations(splitNames, splits, key, attributes, storage) {
  var result = {};
  var thenables = [];
  splitNames.forEach(function (splitName) {
    var evaluation = getEvaluation(splits[splitName], key, attributes, storage);

    if (thenable(evaluation)) {
      thenables.push(evaluation.then(function (res) {
        result[splitName] = res;
      }));
    } else {
      result[splitName] = evaluation;
    }
  });
  return thenables.length > 0 ? Promise.all(thenables).then(function () {
    return result;
  }) : result;
}