import objectAssign from 'object-assign';
import ClientFactory from './client';
import { validateAttributes, validateEvent, validateEventValue, validateEventProperties, validateKey, validateSplit, validateSplits, validateTrafficType, validateIfDestroyed, validateIfReady } from '../utils/inputValidation';
import { startsWith } from '../utils/lang';
import { STORAGE_REDIS, CONTROL, CONTROL_WITH_CONFIG } from '../utils/constants';
/**
 * We will validate the input before actually executing the client methods. We should "guard" the client here,
 * while not polluting the "real" implementation of those methods.
 */

function ClientInputValidationLayer(context, isKeyBinded, isTTBinded) {
  var settings = context.get(context.constants.SETTINGS);
  var isStorageSync = settings.storage.type !== STORAGE_REDIS; // instantiate the client

  var client = ClientFactory(context); // Keep a reference to the original methods

  var clientGetTreatment = client.getTreatment;
  var clientGetTreatmentWithConfig = client.getTreatmentWithConfig;
  var clientGetTreatments = client.getTreatments;
  var clientGetTreatmentsWithConfig = client.getTreatmentsWithConfig;
  var clientTrack = client.track;
  /**
   * Avoid repeating this validations code
   */

  function validateEvaluationParams(maybeKey, maybeSplitOrSplits, maybeAttributes, methodName) {
    var multi = startsWith(methodName, 'getTreatments');
    var key = isKeyBinded ? maybeKey : validateKey(maybeKey, methodName);
    var splitOrSplits = multi ? validateSplits(maybeSplitOrSplits, methodName) : validateSplit(maybeSplitOrSplits, methodName);
    var attributes = validateAttributes(maybeAttributes, methodName);
    var isOperational = validateIfDestroyed(context);
    validateIfReady(context, methodName);
    var valid = isOperational && key && splitOrSplits && attributes !== false;
    return {
      valid: valid,
      key: key,
      splitOrSplits: splitOrSplits,
      attributes: attributes
    };
  }

  client.getTreatment = function getTreatment(maybeKey, maybeSplit, maybeAttributes) {
    var params = validateEvaluationParams(maybeKey, maybeSplit, maybeAttributes, 'getTreatment');

    if (params.valid) {
      return clientGetTreatment(params.key, params.splitOrSplits, params.attributes);
    } else {
      if (isStorageSync) return CONTROL;
      return Promise.resolve(CONTROL);
    }
  };

  client.getTreatmentWithConfig = function getTreatmentWithConfig(maybeKey, maybeSplit, maybeAttributes) {
    var params = validateEvaluationParams(maybeKey, maybeSplit, maybeAttributes, 'getTreatmentWithConfig');

    if (params.valid) {
      return clientGetTreatmentWithConfig(params.key, params.splitOrSplits, params.attributes);
    } else {
      if (isStorageSync) return objectAssign({}, CONTROL_WITH_CONFIG);
      return Promise.resolve(objectAssign({}, CONTROL_WITH_CONFIG));
    }
  };

  client.getTreatments = function getTreatments(maybeKey, maybeSplits, maybeAttributes) {
    var params = validateEvaluationParams(maybeKey, maybeSplits, maybeAttributes, 'getTreatments');

    if (params.valid) {
      return clientGetTreatments(params.key, params.splitOrSplits, params.attributes);
    } else {
      var res = {};
      if (params.splitOrSplits) params.splitOrSplits.forEach(function (split) {
        return res[split] = CONTROL;
      });
      if (isStorageSync) return res;
      return Promise.resolve(res);
    }
  };

  client.getTreatmentsWithConfig = function getTreatmentsWithConfig(maybeKey, maybeSplits, maybeAttributes) {
    var params = validateEvaluationParams(maybeKey, maybeSplits, maybeAttributes, 'getTreatmentsWithConfig');

    if (params.valid) {
      return clientGetTreatmentsWithConfig(params.key, params.splitOrSplits, params.attributes);
    } else {
      var res = {};
      if (params.splitOrSplits) params.splitOrSplits.forEach(function (split) {
        return res[split] = objectAssign({}, CONTROL_WITH_CONFIG);
      });
      if (isStorageSync) return res;
      return Promise.resolve(res);
    }
  };

  client.track = function track(maybeKey, maybeTT, maybeEvent, maybeEventValue, maybeProperties) {
    var key = isKeyBinded ? maybeKey : validateKey(maybeKey, 'track');
    var tt = isTTBinded ? maybeTT : validateTrafficType(maybeTT, 'track');
    var event = validateEvent(maybeEvent, 'track');
    var eventValue = validateEventValue(maybeEventValue, 'track');

    var _validateEventPropert = validateEventProperties(maybeProperties, 'track'),
        properties = _validateEventPropert.properties,
        size = _validateEventPropert.size;

    var isOperational = validateIfDestroyed(context);

    if (isOperational && key && tt && event && eventValue !== false && properties !== false) {
      return clientTrack(key, tt, event, eventValue, properties, size);
    } else {
      if (isStorageSync) return false;
      return Promise.resolve(false);
    }
  };

  return client;
}

export default ClientInputValidationLayer;