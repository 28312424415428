import { get } from '../utils/lang';
import ClientAttributesDecorationLayer from './attributesDecoration';
import { LOCALHOST_MODE } from '../utils/constants';
import { validateKey, validateTrafficType } from '../utils/inputValidation';

function BrowserClientFactory(context) {
  var _client$track;

  var settings = context.get(context.constants.SETTINGS);
  var maybeKey = get(settings, 'core.key', undefined);
  var maybeTT = get(settings, 'core.trafficType', undefined);

  if (settings.mode === LOCALHOST_MODE && maybeKey === undefined) {
    settings.core.key = 'localhost_key';
  } else {
    settings.core.key = validateKey(maybeKey, 'Client instantiation');
  } // Key is also binded to the .track method. Same thing happens with trafficType but only if present on configs. (not required)


  var trackBindings = [settings.core.key];

  if (maybeTT !== undefined) {
    var tt = validateTrafficType(maybeTT, 'Client instantiation');
    settings.core.trafficType = tt;
    trackBindings.push(tt);
  }

  var client = ClientAttributesDecorationLayer(context, true, trackBindings.length > 1);
  client.isBrowserClient = true; // In the browser land, we can bind the key and the traffic type (if provided)

  client.getTreatment = client.getTreatment.bind(client, settings.core.key);
  client.getTreatmentWithConfig = client.getTreatmentWithConfig.bind(client, settings.core.key);
  client.getTreatments = client.getTreatments.bind(client, settings.core.key);
  client.getTreatmentsWithConfig = client.getTreatmentsWithConfig.bind(client, settings.core.key);
  client.track = (_client$track = client.track).bind.apply(_client$track, [client].concat(trackBindings));
  return client;
}

export default BrowserClientFactory;