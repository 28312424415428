import logFactory from '../utils/logger';
var log = logFactory('splitio-events');
import tracker from '../utils/timeTracker';
import repeat from '../utils/fn/repeat';
import eventsService from '../services/events';
import eventsBulkRequest from '../services/events/bulk';

var EventsFactory = function EventsFactory(context) {
  var settings = context.get(context.constants.SETTINGS);
  var storage = context.get(context.constants.STORAGE);

  var pushEvents = function pushEvents() {
    if (storage.events.isEmpty()) return Promise.resolve();
    log.info("Pushing " + storage.events.state().length + " queued events.");
    var latencyTrackerStop = tracker.start(tracker.TaskNames.EVENTS_PUSH);
    var json = JSON.stringify(storage.events.toJSON());

    var wrapUpCb = function wrapUpCb() {
      return latencyTrackerStop();
    };

    storage.events.clear(); // we always clear the queue.

    return eventsService(eventsBulkRequest(settings, {
      body: json
    })).then(wrapUpCb).catch(wrapUpCb);
  };

  var stopEventPublisherTimeout = false;
  var stopEventsPublisher = false;

  var startEventsPublisher = function startEventsPublisher() {
    return stopEventsPublisher = repeat(function (schedulePublisher) {
      return pushEvents().then(function () {
        return schedulePublisher();
      });
    }, settings.scheduler.eventsPushRate);
  };

  return {
    start: function start() {
      // On the browser there may be a wish to wait an specific amount of seconds before the first push.
      if (settings.startup.eventsFirstPushWindow > 0) {
        stopEventPublisherTimeout = setTimeout(startEventsPublisher, settings.startup.eventsFirstPushWindow);
      } else {
        startEventsPublisher();
      }
    },
    flush: function flush() {
      return pushEvents();
    },
    stop: function stop() {
      stopEventPublisherTimeout && clearTimeout(stopEventPublisherTimeout);
      stopEventsPublisher && stopEventsPublisher();
    },
    flushAndResetTimer: function flushAndResetTimer() {
      // Reset the timer and push the events.
      log.info('Flushing events and reseting timer.');
      stopEventsPublisher && stopEventsPublisher.reset();
      return pushEvents();
    }
  };
};

export default EventsFactory;