import ManagerFactory from './manager';
import StorageFactory from './storage';
import ReadinessGateFacade from './readiness';
import SettingsFactory from './utils/settings';
import Context from './utils/context';
import keyParser from './utils/key/parser';
import logFactory, { API } from './utils/logger';
var log = logFactory('splitio');
import tracker from './utils/timeTracker';
import SplitFactoryOnline from './factory/online';
import SplitFactoryOffline from './factory/offline';
import sdkStatusManager from './readiness/statusManager';
import { LOCALHOST_MODE } from './utils/constants';
import { validateApiKey, validateKey, validateTrafficType } from './utils/inputValidation';
import IntegrationsManagerFactory from './integrations';
import ImpressionCounter from './impressions/counter';

var buildInstanceId = function buildInstanceId(key, trafficType) {
  return (key.matchingKey ? key.matchingKey : key) + "-" + (key.bucketingKey ? key.bucketingKey : key) + "-" + (trafficType !== undefined ? trafficType : '');
};

export function SplitFactory(config) {
  // Cache instances created per factory.
  var clientInstances = {}; // Tracking times. We need to do it here because we need the storage created.

  var readyLatencyTrackers = {
    splitsReadyTracker: tracker.start(tracker.TaskNames.SPLITS_READY),
    segmentsReadyTracker: tracker.start(tracker.TaskNames.SEGMENTS_READY),
    sdkReadyTracker: tracker.start(tracker.TaskNames.SDK_READY)
  };
  var context = new Context(); // Put settings config within context

  var settings = SettingsFactory(config);
  context.put(context.constants.SETTINGS, settings); // We will just log and allow for the SDK to end up throwing an SDK_TIMEOUT event for devs to handle.

  validateApiKey(settings.core.authorizationKey); // Put readiness and statusManager within context
  // Done before adding storage, to let it access readiness gate synchronously

  var gateFactory = ReadinessGateFacade();
  var readiness = gateFactory(settings.startup.readyTimeout);
  context.put(context.constants.READINESS, readiness);
  var statusManager = sdkStatusManager(context);
  context.put(context.constants.STATUS_MANAGER, statusManager); // Put storage config within context

  var storage = StorageFactory(context);
  context.put(context.constants.STORAGE, storage); // Put counter

  var impressionsCounter = new ImpressionCounter(); // Instantiates new counter for Impressions

  context.put(context.constants.IMPRESSIONS_COUNTER, impressionsCounter); // Put integrationsManager within context.
  // It needs to access the storage, settings and potentially other pieces, so it's registered after them.

  var integrationsManager = IntegrationsManagerFactory(context);
  context.put(context.constants.INTEGRATIONS_MANAGER, integrationsManager); // Define which type of factory to use

  var splitFactory = settings.mode === LOCALHOST_MODE ? SplitFactoryOffline : SplitFactoryOnline;

  var _splitFactory = splitFactory(context, readyLatencyTrackers),
      mainClientInstance = _splitFactory.api,
      mainClientMetricCollectors = _splitFactory.metricCollectors; // It makes no sense to have multiple instances of the manager.


  var managerInstance = ManagerFactory(storage.splits, context);
  var parsedDefaultKey = keyParser(settings.core.key);
  var defaultInstanceId = buildInstanceId(parsedDefaultKey, settings.core.trafficType);
  clientInstances[defaultInstanceId] = mainClientInstance;
  log.info('New Split SDK instance created.');
  return {
    // Split evaluation and event tracking engine
    client: function client(key, trafficType) {
      if (key === undefined) {
        log.debug('Retrieving default SDK client.');
        return mainClientInstance;
      }

      if (typeof storage.shared != 'function') {
        throw new Error('Shared Client not supported by the storage mechanism. Create isolated instances instead.');
      } // Validate the key value


      var validKey = validateKey(key, 'Shared Client instantiation');

      if (validKey === false) {
        throw new Error('Shared Client needs a valid key.');
      }

      var validTrafficType;

      if (trafficType !== undefined) {
        validTrafficType = validateTrafficType(trafficType, 'Shared Client instantiation');

        if (validTrafficType === false) {
          throw new Error('Shared Client needs a valid traffic type or no traffic type at all.');
        }
      }

      var instanceId = buildInstanceId(validKey, validTrafficType);

      if (!clientInstances[instanceId]) {
        var sharedSettings = settings.overrideKeyAndTT(validKey, validTrafficType);
        var sharedContext = new Context();

        var _readiness = gateFactory(sharedSettings.startup.readyTimeout);

        sharedContext.put(context.constants.READY_FROM_CACHE, context.get(context.constants.READY_FROM_CACHE, true));
        sharedContext.put(context.constants.READINESS, _readiness); // for shared clients, the internal offset of added/removed SDK_READY callbacks is -1

        sharedContext.put(context.constants.STATUS_MANAGER, sdkStatusManager(sharedContext, -1));
        sharedContext.put(context.constants.SETTINGS, sharedSettings);
        sharedContext.put(context.constants.STORAGE, storage.shared(sharedSettings));
        sharedContext.put(context.constants.IMPRESSIONS_COUNTER, impressionsCounter); // As shared clients reuse all the storage information, we don't need to check here if we
        // will use offline or online mode. We should stick with the original decision.

        clientInstances[instanceId] = splitFactory(sharedContext, false, mainClientMetricCollectors).api;
        log.info('New shared client instance created.');
      } else {
        log.debug('Retrieving existing SDK client.');
      }

      return clientInstances[instanceId];
    },
    // Manager API to explore available information
    manager: function manager() {
      log.info('Manager instance retrieved.');
      return managerInstance;
    },
    // Logger wrapper API
    Logger: API,
    // Expose SDK settings
    settings: settings
  };
}