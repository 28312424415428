import ClientInputValidationLayer from './inputValidation';
import AttributesCacheInMemory from '../storage/AttributesCache/InMemory';
import { validateAttributesDeep } from '../utils/inputValidation/attributes';
import logFactory from '../utils/logger';
import objectAssign from 'object-assign';
var log = logFactory('splitio-client');
/**
 * Add in memory attributes storage methods and combine them with any attribute received from the getTreatment/s call 
 */

export default function ClientAttributesDecorationLayer(context, isKeyBinded, isTTBinded) {
  var client = ClientInputValidationLayer(context, isKeyBinded, isTTBinded);
  var attributeStorage = new AttributesCacheInMemory(); // Keep a reference to the original methods

  var clientGetTreatment = client.getTreatment;
  var clientGetTreatmentWithConfig = client.getTreatmentWithConfig;
  var clientGetTreatments = client.getTreatments;
  var clientGetTreatmentsWithConfig = client.getTreatmentsWithConfig;
  /**
   * Add an attribute to client's in memory attributes storage
   * 
   * @param {string} attributeName Attrinute name
   * @param {string, number, boolean, list} attributeValue Attribute value
   * @returns {boolean} true if the attribute was stored and false otherways
   */

  client.setAttribute = function (attributeName, attributeValue) {
    var attribute = {};
    attribute[attributeName] = attributeValue;
    if (!validateAttributesDeep(attribute)) return false;
    log.debug("stored " + attributeValue + " for attribute " + attributeName);
    return attributeStorage.setAttribute(attributeName, attributeValue);
  };
  /**
   * Returns the attribute with the given key
   * 
   * @param {string} attributeName Attribute name
   * @returns {Object} Attribute with the given key
   */


  client.getAttribute = function (attributeName) {
    log.debug("retrieved attribute " + attributeName);
    return attributeStorage.getAttribute(attributeName + '');
  };
  /**
   * Add to client's in memory attributes storage the attributes in 'attributes'
   * 
   * @param {Object} attributes Object with attributes to store
   * @returns true if attributes were stored an false otherways
   */


  client.setAttributes = function (attributes) {
    if (!validateAttributesDeep(attributes)) return false;
    return attributeStorage.setAttributes(attributes);
  };
  /**
   * Return all the attributes stored in client's in memory attributes storage
   * 
   * @returns {Object} returns all the stored attributes
   */


  client.getAttributes = function () {
    return attributeStorage.getAll();
  };
  /**
   * Removes from client's in memory attributes storage the attribute with the given key
   * 
   * @param {string} attributeName 
   * @returns {boolean} true if attribute was removed and false otherways
   */


  client.removeAttribute = function (attributeName) {
    log.debug("removed attribute " + attributeName);
    return attributeStorage.removeAttribute(attributeName + '');
  };
  /**
   * Remove all the stored attributes in the client's in memory attribute storage
   */


  client.clearAttributes = function () {
    return attributeStorage.clear();
  };

  client.getTreatment = function (maybeKey, maybeSplit, maybeAttributes) {
    return clientGetTreatment(maybeKey, maybeSplit, combineAttributes(maybeAttributes));
  };

  client.getTreatmentWithConfig = function (maybeKey, maybeSplit, maybeAttributes) {
    return clientGetTreatmentWithConfig(maybeKey, maybeSplit, combineAttributes(maybeAttributes));
  };

  client.getTreatments = function (maybeKey, maybeSplits, maybeAttributes) {
    return clientGetTreatments(maybeKey, maybeSplits, combineAttributes(maybeAttributes));
  };

  client.getTreatmentsWithConfig = function (maybeKey, maybeSplits, maybeAttributes) {
    return clientGetTreatmentsWithConfig(maybeKey, maybeSplits, combineAttributes(maybeAttributes));
  };

  function combineAttributes(maybeAttributes) {
    var storedAttributes = attributeStorage.getAll();

    if (Object.keys(storedAttributes).length > 0) {
      return objectAssign({}, storedAttributes, maybeAttributes);
    }

    return maybeAttributes;
  }

  return client;
}