var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { SplitComponent } from './SplitClient';
import { VERSION, WARN_SF_CONFIG_AND_FACTORY, ERROR_SF_NO_CONFIG_AND_FACTORY } from './constants';
import { getSplitFactory, destroySplitFactory } from './utils';
/**
 * SplitFactory will initialize the Split SDK and its main client, listen for its events in order to update the Split Context,
 * and automatically shutdown and release resources when it is unmounted. SplitFactory must wrap other components and functions
 * from this library, since they access the Split Context and its elements (factory, clients, etc).
 *
 * The underlying SDK factory and client is set on the constructor, and cannot be changed during the component lifecycle,
 * even if the component is updated with a different config or factory prop.
 *
 * @see {@link https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK}
 */
var SplitFactory = /** @class */ (function (_super) {
    __extends(SplitFactory, _super);
    function SplitFactory(props) {
        var _this = _super.call(this, props) || this;
        // Log warning and error
        var propFactory = props.factory, config = props.config;
        if (!config && !propFactory) {
            console.error(ERROR_SF_NO_CONFIG_AND_FACTORY);
        }
        if (config && propFactory) {
            console.log(WARN_SF_CONFIG_AND_FACTORY);
        }
        // Instantiate factory
        var factory = null;
        if (propFactory) {
            factory = propFactory;
        }
        else {
            if (config) {
                // Don't try this at home. Used to overwrite the settings version when we create our own factory.
                config.version = VERSION;
                // We use an idempotent variant of the Split factory builder (i.e., given the same config, it returns the same already
                // created instance), since React component constructors is part of render-phase and can be invoked multiple times.
                factory = getSplitFactory(config);
            }
        }
        _this.isFactoryExternal = propFactory ? true : false;
        // Instantiate main client.
        var client = factory ? factory.client() : null;
        _this.state = {
            client: client,
            factory: factory,
        };
        return _this;
    }
    SplitFactory.prototype.componentWillUnmount = function () {
        // only destroy the client if the factory was created internally. Otherwise, the shutdown must be handled by the user
        if (!this.isFactoryExternal && this.state.factory) {
            destroySplitFactory(this.state.factory);
        }
    };
    SplitFactory.prototype.render = function () {
        var _a = this.state, factory = _a.factory, client = _a.client;
        return (React.createElement(SplitComponent, __assign({}, this.props, { factory: factory, client: client })));
    };
    SplitFactory.defaultProps = {
        updateOnSdkUpdate: false,
        updateOnSdkTimedout: false,
        updateOnSdkReady: true,
        updateOnSdkReadyFromCache: true,
        children: null,
    };
    return SplitFactory;
}(React.Component));
export default SplitFactory;
