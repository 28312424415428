import EventEmitter from 'events';
var SPLITS_READY = 2;
var SEGMENTS_READY = 4;
var SDK_FIRE_READY = SPLITS_READY | SEGMENTS_READY; // 2 + 4 = 6

var SDK_NOTIFY_UPDATE_SINCE_NOW = 8;
var SDK_FIRE_UPDATE = SDK_FIRE_READY | SDK_NOTIFY_UPDATE_SINCE_NOW; // 6 + 8 = 14

var Events = {
  SDK_READY_TIMED_OUT: 'init::timeout',
  SDK_READY: 'init::ready',
  SDK_READY_FROM_CACHE: 'init::cache-ready',
  SDK_SPLITS_ARRIVED: 'state::splits-arrived',
  SDK_SEGMENTS_ARRIVED: 'state::segments-arrived',
  SDK_SPLITS_CACHE_LOADED: 'state::splits-cache-loaded',
  SDK_UPDATE: 'state::update',
  READINESS_GATE_CHECK_STATE: 'state::check'
};
/**
 * Machine state to handle the ready / update event propagation.
 */

function GateContext() {
  // Splits are shared through all instances of the same SDK.
  var splitsStatus = 0;
  var splits = new EventEmitter();
  splits.SDK_SPLITS_CACHE_LOADED = Events.SDK_SPLITS_CACHE_LOADED;
  splits.SDK_SPLITS_ARRIVED = Events.SDK_SPLITS_ARRIVED; // references counter: how many

  var refCount = 0;

  function ReadinessGateFactory(splits, segments) {
    var gate = new EventEmitter();
    var segmentsStatus = 0;
    var status = 0;
    gate.on(Events.READINESS_GATE_CHECK_STATE, function () {
      // @TODO catch and handle user callback errors. Required for localhost and consumer modes. In standalone mode, it is done in 'SplitChangesUpdater'.
      if (status !== SDK_FIRE_UPDATE && splitsStatus + segmentsStatus === SDK_FIRE_READY) {
        status = SDK_FIRE_UPDATE;
        gate.emit(Events.SDK_READY);
      } else if (status === SDK_FIRE_UPDATE) {
        gate.emit(Events.SDK_UPDATE);
      }
    });
    splits.on(Events.SDK_SPLITS_ARRIVED, function (isSplitKill) {
      // `isSplitKill` condition avoids an edge-case of wrongly emitting SDK_READY if:
      // - `/mySegments` fetch and SPLIT_KILL occurs before `/splitChanges` fetch, and
      // - storage has cached splits (for which case `splitsStorage.killLocally` can return true)
      if (!isSplitKill) splitsStatus = SPLITS_READY;
      gate.emit(Events.READINESS_GATE_CHECK_STATE);
    });
    splits.once(Events.SDK_SPLITS_CACHE_LOADED, function () {
      try {
        gate.emit(Events.SDK_READY_FROM_CACHE);
      } catch (e) {
        // handle user callback errors
        setTimeout(function () {
          throw e;
        }, 0);
      }
    });
    segments.on(Events.SDK_SEGMENTS_ARRIVED, function () {
      segmentsStatus = SEGMENTS_READY;
      gate.emit(Events.READINESS_GATE_CHECK_STATE);
    });
    return gate;
  }
  /**
   * SDK Readiness Gate Factory
   *
   * The ready state in the browser relay on sharing the splits ready flag across
   * all the gates, and have an extra flag for the segments which is per gate
   * instance.
   */


  function SDKReadinessGateFactory(timeout) {
    if (timeout === void 0) {
      timeout = 0;
    }

    var readinessTimeoutId = 0;
    var segments = new EventEmitter();
    segments.SDK_SEGMENTS_ARRIVED = Events.SDK_SEGMENTS_ARRIVED;
    var gate = ReadinessGateFactory(splits, segments);

    if (timeout > 0) {
      // Add the timeout.
      readinessTimeoutId = setTimeout(function () {
        gate.emit(Events.SDK_READY_TIMED_OUT, 'Split SDK emitted SDK_READY_TIMED_OUT event.');
      }, timeout); // Clear it if the SDK get's ready.

      gate.once(Events.SDK_READY, function () {
        return clearTimeout(readinessTimeoutId);
      });
    }

    gate.SDK_READY = Events.SDK_READY;
    gate.SDK_READY_FROM_CACHE = Events.SDK_READY_FROM_CACHE;
    gate.SDK_UPDATE = Events.SDK_UPDATE;
    gate.SDK_READY_TIMED_OUT = Events.SDK_READY_TIMED_OUT; // New Gate has been created, so increase the counter

    refCount++;
    return {
      // Emitters
      splits: splits,
      segments: segments,
      gate: gate,
      // Cleanup listeners
      destroy: function destroy() {
        segments.removeAllListeners();
        gate.removeAllListeners();
        clearTimeout(readinessTimeoutId);
        if (refCount > 0) refCount--;
        if (refCount === 0) splits.removeAllListeners();
      }
    };
  }

  return SDKReadinessGateFactory;
}

export default GateContext;