/**
Copyright 2016 Split Software

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
**/
import logFactory from '../utils/logger';
import tracker from '../utils/timeTracker';
import repeat from '../utils/fn/repeat';
import metricsService from '../services/metrics';
import metricsTimesServiceRequest from '../services/metrics/times';
import metricsCountersServiceRequest from '../services/metrics/counters';
import { fromLatenciesCollector, fromCountersCollector } from '../services/metrics/dto';
import impressionsService from '../services/impressions';
import impressionsBulkRequest from '../services/impressions/bulk';
import impressionsCountRequest from '../services/impressions/count';
import { fromImpressionsCollector, fromImpressionsCountCollector } from '../services/impressions/dto';
import { SegmentChangesCollector, SplitChangesCollector, MySegmentsCollector, ClientCollector } from './Collectors';
import { OPTIMIZED } from '../utils/constants';
var log = logFactory('splitio-metrics');
var IMPRESSIONS_COUNT_RATE = 1800000; // 30 minutes

var MetricsFactory = function MetricsFactory(context) {
  var impressionsRetries = 0;
  var settings = context.get(context.constants.SETTINGS);
  var storage = context.get(context.constants.STORAGE);
  var impressionsCounter = context.get(context.constants.IMPRESSIONS_COUNTER);
  var shouldPushImpressionsCount = settings.sync.impressionsMode === OPTIMIZED;

  var pushMetrics = function pushMetrics() {
    if (storage.metrics.isEmpty() && storage.count.isEmpty()) return Promise.resolve();
    log.debug('Pushing metrics');
    var latencyTrackerStop = tracker.start(tracker.TaskNames.METRICS_PUSH); // POST latencies

    var latenciesPromise = storage.metrics.isEmpty() ? null : metricsService(metricsTimesServiceRequest(settings, {
      body: JSON.stringify(fromLatenciesCollector(storage.metrics))
    })).then(function () {
      return storage.metrics.clear();
    }).catch(function () {
      return storage.metrics.clear();
    }); // POST counters

    var countersPromise = storage.count.isEmpty() ? null : metricsService(metricsCountersServiceRequest(settings, {
      body: JSON.stringify(fromCountersCollector(storage.count))
    })).then(function () {
      return storage.count.clear();
    }).catch(function () {
      return storage.count.clear();
    });
    return Promise.all([latenciesPromise, countersPromise]).then(function (resp) {
      // After both finishes, track the end and return the results
      latencyTrackerStop();
      return resp;
    });
  };

  var pushImpressions = function pushImpressions() {
    if (storage.impressions.isEmpty()) return Promise.resolve();
    var imprCount = storage.impressions.queue.length;
    log.info("Pushing " + imprCount + " impressions");
    var latencyTrackerStop = tracker.start(tracker.TaskNames.IMPRESSIONS_PUSH);
    return impressionsService(impressionsBulkRequest(settings, {
      body: JSON.stringify(fromImpressionsCollector(storage.impressions, settings))
    })).then(function () {
      impressionsRetries = 0;
      storage.impressions.clear();
    }).catch(function (err) {
      if (impressionsRetries) {
        // For now we retry only once.
        log.warn("Droping " + imprCount + " impressions after retry. Reason " + err + ".");
        impressionsRetries = 0;
        storage.impressions.clear();
      } else {
        impressionsRetries++;
        log.warn("Failed to push " + imprCount + " impressions, keeping data to retry on next iteration. Reason " + err + ".");
      }
    }).then(function () {
      return latencyTrackerStop();
    });
  };

  var pushImpressionsCount = function pushImpressionsCount() {
    var pf = fromImpressionsCountCollector(impressionsCounter);
    var imprCounts = pf.length;
    if (imprCounts === 0) return Promise.resolve();
    log.info("Pushing count of impressions for " + imprCounts + " features");
    return impressionsService(impressionsCountRequest(settings, {
      body: JSON.stringify({
        pf: pf
      })
    })).then(function () {
      impressionsRetries = 0;
    }).catch(function (err) {
      if (impressionsRetries) {
        // For now we retry only once.
        log.warn("Droping count of impressions for " + imprCounts + " features after retry. Reason " + err + ".");
        impressionsRetries = 0;
      } else {
        impressionsRetries++;
        log.warn("Failed to push impressions count for " + imprCounts + " features, keeping data to retry on next iteration. Reason " + err + ".");
      }
    });
  };

  var stopImpressionsPublisher = false;
  var stopPerformancePublisher = false;
  var stopImpressionsCountPublisher = false;
  return {
    start: function start() {
      stopImpressionsPublisher = repeat(function (schedulePublisher) {
        return pushImpressions().then(function () {
          return schedulePublisher();
        });
      }, settings.scheduler.impressionsRefreshRate);
      stopPerformancePublisher = repeat(function (schedulePublisher) {
        return pushMetrics().then(function () {
          return schedulePublisher();
        });
      }, settings.scheduler.metricsRefreshRate);

      if (shouldPushImpressionsCount) {
        stopImpressionsCountPublisher = repeat(function (schedulePublisher) {
          return pushImpressionsCount().then(function () {
            return schedulePublisher();
          });
        }, IMPRESSIONS_COUNT_RATE);
      }
    },
    flush: function flush() {
      if (shouldPushImpressionsCount) pushImpressionsCount();
      return pushImpressions();
    },
    stop: function stop() {
      stopImpressionsPublisher && stopImpressionsPublisher();
      stopPerformancePublisher && stopPerformancePublisher();
      stopImpressionsCountPublisher && stopImpressionsCountPublisher();
    },
    // Metrics collectors
    collectors: {
      segmentChanges: new SegmentChangesCollector(storage),
      splitChanges: new SplitChangesCollector(storage),
      mySegments: new MySegmentsCollector(storage),
      client: new ClientCollector(storage)
    }
  };
};

export default MetricsFactory;