// SDK Modes
export var LOCALHOST_MODE = 'localhost';
export var STANDALONE_MODE = 'standalone';
export var PRODUCER_MODE = 'producer';
export var CONSUMER_MODE = 'consumer'; // Storage types

export var STORAGE_MEMORY = 'MEMORY';
export var STORAGE_REDIS = 'REDIS';
export var STORAGE_LOCALSTORAGE = 'LOCALSTORAGE'; // Special treatments

export var CONTROL = 'control';
export var CONTROL_WITH_CONFIG = {
  treatment: CONTROL,
  config: null
}; // Constants for unknown and not-applicable values

export var UNKNOWN = 'unknown';
export var NA = 'NA'; // Integration types

export var GOOGLE_ANALYTICS_TO_SPLIT = 'GOOGLE_ANALYTICS_TO_SPLIT';
export var SPLIT_TO_GOOGLE_ANALYTICS = 'SPLIT_TO_GOOGLE_ANALYTICS'; // Integration data types

export var SPLIT_IMPRESSION = 'IMPRESSION';
export var SPLIT_EVENT = 'EVENT'; // Split filters metadata.
// Ordered according to their precedency when forming the filter query string: `&names=<values>&prefixes=<values>`

export var FILTERS_METADATA = [{
  type: 'byName',
  maxLength: 400,
  queryParam: 'names='
}, {
  type: 'byPrefix',
  maxLength: 50,
  queryParam: 'prefixes='
}]; // Impression collection modes

export var DEBUG = 'DEBUG';
export var OPTIMIZED = 'OPTIMIZED';