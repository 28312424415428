import thenable from '../../utils/promise/thenable';
/**
 * Kill `splitName` at `this` split storage, setting `defaultTreatment` and `changeNumber`,
 * only if the split exists and its `changeNumber` is older than the given one.
 * Used for SPLIT_KILL push notifications.
 *
 * @param {string} splitName
 * @param {string} defaultTreatment
 * @param {number} changeNumber
 * @returns {Promise} a promise that is resolved once the split kill is performed.
 * The fulfillment value is a boolean: `true` if the kill success updating the split or `false` if no split is updated, for instance,
 * if the `changeNumber` is old, if the `splitName` is not found, or if the storage fails to apply the update.
 */

export default function killLocally(splitName, defaultTreatment, changeNumber) {
  var _this = this;

  var split = this.getSplit(splitName);
  var splitPromise = thenable(split) ? split : Promise.resolve(split);
  return splitPromise.then(function (split) {
    if (split) {
      var parsedSplit = JSON.parse(split);

      if (!parsedSplit.changeNumber || parsedSplit.changeNumber < changeNumber) {
        parsedSplit.killed = true;
        parsedSplit.defaultTreatment = defaultTreatment;
        parsedSplit.changeNumber = changeNumber;
        var newSplit = JSON.stringify(parsedSplit);
        return _this.addSplit(splitName, newSplit);
      }
    }

    return false;
  });
}