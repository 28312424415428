/**
Copyright 2016 Split Software

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
**/
import logFactory from '../../utils/logger';
var log = logFactory('splitio-producer:split-changes');
import splitChangesFetcher from '../fetcher/SplitChanges';
import parseSegments from '../../engine/parser/segments';
import { SplitError } from '../../utils/lang/Errors';
import { _Set, setToArray } from '../../utils/lang/Sets';
import thenable from '../../utils/promise/thenable'; // For server-side segments storage, returns true if all registered segments have been fetched (changeNumber !== -1)

function checkAllSegmentsExist(segmentsStorage) {
  return segmentsStorage.getRegisteredSegments().every(function (segmentName) {
    return segmentsStorage.getChangeNumber(segmentName) !== -1;
  });
}

function computeSplitsMutation(entries) {
  var computed = entries.reduce(function (accum, split) {
    if (split.status === 'ACTIVE') {
      accum.added.push([split.name, JSON.stringify(split)]);
      parseSegments(split.conditions).forEach(function (segmentName) {
        accum.segments.add(segmentName);
      });
    } else {
      accum.removed.push(split.name);
    }

    return accum;
  }, {
    added: [],
    removed: [],
    segments: new _Set()
  });
  computed.segments = setToArray(computed.segments);
  return computed;
}

export default function SplitChangesUpdaterFactory(context, isNode) {
  if (isNode === void 0) {
    isNode = false;
  }

  var _context$getAll = context.getAll(),
      settings = _context$getAll[context.constants.SETTINGS],
      readiness = _context$getAll[context.constants.READINESS],
      storage = _context$getAll[context.constants.STORAGE],
      metricCollectors = _context$getAll[context.constants.COLLECTORS];

  var splitsEventEmitter = readiness.splits;
  var startingUp = true;
  var readyOnAlreadyExistentState = true;
  /**
   * Split updater returns a promise that resolves with a `false` boolean value if it fails to fetch splits or synchronize them with the storage.
   *
   * @param {number | undefined} retry current number of retry attemps. this param is only set by SplitChangesUpdater itself.
   * @param {boolean | undefined} noCache true to revalidate data to fetch
   */

  return function SplitChangesUpdater(retry, noCache) {
    if (retry === void 0) {
      retry = 0;
    }

    function splitChanges(since) {
      log.debug("Spin up split update using since = " + since);
      var fetcherPromise = splitChangesFetcher(settings, since, startingUp, metricCollectors, isNode, noCache).then(function (splitChanges) {
        startingUp = false;
        var mutation = computeSplitsMutation(splitChanges.splits);
        log.debug("New splits " + mutation.added.length);
        log.debug("Removed splits " + mutation.removed.length);
        log.debug("Segment names collected " + mutation.segments); // Write into storage
        // @TODO if allowing custom storages, wrap errors as SplitErrors to distinguish from user callback errors

        return Promise.all([// calling first `setChangenumber` method, to perform cache flush if split filter queryString changed
        storage.splits.setChangeNumber(splitChanges.till), storage.splits.addSplits(mutation.added), storage.splits.removeSplits(mutation.removed), storage.segments.registerSegments(mutation.segments)]).then(function () {
          // On server-side SDK, we must check that all registered segments have been fetched
          if (readyOnAlreadyExistentState || since !== splitChanges.till && (!isNode || checkAllSegmentsExist(storage.segments))) {
            readyOnAlreadyExistentState = false;
            splitsEventEmitter.emit(splitsEventEmitter.SDK_SPLITS_ARRIVED);
          }
        });
      }).catch(function (error) {
        // handle user callback errors
        if (!(error instanceof SplitError)) {
          setTimeout(function () {
            throw error;
          }, 0);
          startingUp = false; // Stop retrying.
        }

        log.warn("Error while doing fetch of Splits. " + error);

        if (startingUp && settings.startup.retriesOnFailureBeforeReady > retry) {
          retry += 1;
          log.info("Retrying download of splits #" + retry + ". Reason: " + error);
          return SplitChangesUpdater(retry, noCache);
        } else {
          startingUp = false;
        }

        return false;
      }); // After triggering the requests, if we have cached splits information let's notify that.
      // There is no need to emit the event asynchronously to let attach a listener for SDK_READY_FROM_CACHE,
      // because the current event-loop callback executes after SplitFactory is created.

      if (startingUp && storage.splits.checkCache()) {
        splitsEventEmitter.emit(splitsEventEmitter.SDK_SPLITS_CACHE_LOADED);
      }

      return fetcherPromise;
    } // @TODO check why e2e tests take so much time when sync storage result is not handled in a promise


    var since = storage.splits.getChangeNumber();
    var sincePromise = thenable(since) ? since : Promise.resolve(since);
    return sincePromise.then(splitChanges);
  };
}